import { BaseBloc } from '@core/utils/bloc';
import { Repository } from '@core/utils/repository';
import { handleMessage, requestMessage } from '@business/messages';
import { showDeleteConfirmation } from '@modules/common';
import { switchMap } from 'rxjs/operators';
import { HelpArticleResponse, HelpArticleId, HelpArticleRequest } from '@business/entities/helpArticle';
import { HelpArticleAPI } from '@business/api/helpArticle';

export class HelpArticleBloc extends BaseBloc {
  private readonly helpArticleRepo = new Repository<HelpArticleResponse>({
    getItemId: helpArticle => helpArticle.id,
  });

  readonly helpArticles$ = this.helpArticleRepo.items$;
  readonly selectHelpArticle = this.helpArticleRepo.selectItem;

  onReset() {
    this.helpArticleRepo.reset();
  }

  fetchHelpArticles = (id: string | undefined = '0') => {
    id = !id ? '0' : id;
    return HelpArticleAPI.fetchHelpArticles(id).pipe(
      this.helpArticleRepo.ops.reset(),
      handleMessage({ error: requestMessage('fetch_help_articles_error') }),
    );
  };

  fetchHelpArticle = (id: HelpArticleId) => {
    return HelpArticleAPI.fetchHelpArticle(id).pipe(
      this.helpArticleRepo.ops.upsertOne(item => ({ item })),
      handleMessage({ error: requestMessage('fetch_help_articles_error') }),
    );
  };

  createHelpArticle = (helpArticle: HelpArticleRequest) => {
    return HelpArticleAPI.createHelpArticle(helpArticle).pipe(
      this.helpArticleRepo.ops.addOne(item => item),
      handleMessage({
        type: requestMessage('create_help_article'),
      }),
    );
  };

  updateHelpArticle = (id: HelpArticleId, helpArticle: HelpArticleRequest) => {
    return HelpArticleAPI.updateHelpArticle(id, helpArticle).pipe(
      this.helpArticleRepo.ops.upsertOne(item => ({ item })),
      handleMessage({
        type: requestMessage('update_help_article'),
      }),
    );
  };

  deleteHelpArticle = (id: HelpArticleId) => {
    return showDeleteConfirmation('Delete Help Article', 'Do you really want to remove this help article?').pipe(
      switchMap(() => HelpArticleAPI.deleteHelpArticle(id)),
      this.helpArticleRepo.ops.removeOne(() => id),
      handleMessage({
        type: requestMessage('delete_help_article'),
      }),
    );
  };

  search = (search: string) => {
    return HelpArticleAPI.search(search).pipe(
      handleMessage({
        error: requestMessage('fetch_general_error'),
      }),
    );
  };
}
