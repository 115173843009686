import { DashboardAPI } from '@business/api/dashboard_api';
import {
  DashboardFilters,
  DashboardId,
  DashboardResponse,
  DashboardWidgetId,
  DashboardWidgetResponse,
  DashboardLink,
} from '@business/entities/dashboard';
import { handleMessage, requestMessage } from '@business/messages';
import { BaseBloc } from '@core/utils/bloc';
import { R } from '@core/utils/r';
import { Repository } from '@core/utils/repository';
import { showDeleteConfirmation } from '@modules/common';
import { ReportBuilderFormData } from '@modules/dynamicReports/ReportForm';
import { forkJoin, BehaviorSubject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

export class DashboardBloc extends BaseBloc {
  private readonly dashboardRepo = new Repository<DashboardResponse>({
    getItemId: dashboard => dashboard.id,
  });

  readonly links$ = new BehaviorSubject<DashboardLink[]>([]);

  readonly dashboards$ = this.dashboardRepo.items$.pipe(map(ds => R.sortBy(ds, d => d.title)));
  selectDashboard = this.dashboardRepo.selectItem;

  onReset() {
    this.dashboardRepo.reset();
  }

  fetchDashboards = () => {
    return DashboardAPI.fetchDashboards().pipe(this.dashboardRepo.ops.reset(), handleMessage({ error: requestMessage('fetch_dashboards_error') }));
  };

  fetchDashboard = (id: DashboardId, filters?: DashboardFilters) => {
    return DashboardAPI.fetchDashboard(id, filters).pipe(
      this.dashboardRepo.ops.upsertOne(item => ({ item })),
      handleMessage({ error: requestMessage('fetch_dashboards_error') }),
    );
  };

  createDashboard = (data: Partial<DashboardResponse>) => {
    return DashboardAPI.createDashboard(data).pipe(
      this.dashboardRepo.ops.addOne(item => item),
      handleMessage({
        type: requestMessage('create_dashboard'),
      }),
    );
  };

  updateDashboard = (id: DashboardId, data: Partial<DashboardResponse>) => {
    return DashboardAPI.updateDashboard(id, data).pipe(
      this.dashboardRepo.ops.upsertOne(item => ({ item })),
      handleMessage({
        type: requestMessage('update_dashboard'),
      }),
    );
  };

  makeDefault = (id: DashboardId) => {
    return DashboardAPI.makeDefault(id).pipe(
      this.dashboardRepo.ops.upsertOne(item => ({ item })),
      handleMessage({
        type: requestMessage('update_dashboard'),
      }),
    );
  };

  duplicateDashboard = (id: DashboardId, data: Partial<DashboardResponse>) => {
    return DashboardAPI.duplicateDashboard(id, data).pipe(
      this.dashboardRepo.ops.upsertOne(item => ({ item })),
      handleMessage({
        type: requestMessage('update_dashboard'),
      }),
    );
  };

  deleteDashboard = (id: DashboardId) => {
    return showDeleteConfirmation('Delete Dashboard', 'Do you really want to remove this dashboard?').pipe(
      switchMap(() => DashboardAPI.deleteDashboard(id)),
      this.dashboardRepo.ops.removeOne(() => id),
      handleMessage({
        type: requestMessage('delete_dashboard'),
      }),
    );
  };

  createWidget = (id: DashboardId, data: ReportBuilderFormData) => {
    return DashboardAPI.createWidget(id, data).pipe(
      this.dashboardRepo.ops.upsertOne(item => ({ item })),
      handleMessage({
        type: requestMessage('create_dashboard'),
      }),
    );
  };

  updateWidget = (widgetId: DashboardWidgetId, data: Partial<ReportBuilderFormData>) => {
    return DashboardAPI.updateWidget(widgetId, data).pipe(this.dashboardRepo.ops.upsertOne(item => ({ item })));
  };

  deleteWidget = (dashboardId: DashboardId, id: DashboardWidgetId) => {
    return showDeleteConfirmation('Delete Widget', 'Do you really want to remove this widget?').pipe(
      switchMap(() => DashboardAPI.deleteWidget(id)),
      this.dashboardRepo.ops.updateOne(() => ({
        id: dashboardId,
        changes: d => ({ widgets: d.widgets.filter(w => w.id !== id) }),
      })),
    );
  };

  updateBulkWidgets = (dashboardId: DashboardId, widgets: DashboardWidgetResponse[]) => {
    this.dashboardRepo.updateOne({ id: dashboardId, changes: () => ({ widgets }) });
    DashboardAPI.updateAllWidgets(dashboardId, widgets).subscribe();
  };

  updateAllWidgets = (dashboardId: DashboardId, widgets: DashboardWidgetResponse[]) => {
    this.dashboardRepo.updateOne({ id: dashboardId, changes: () => ({ widgets }) });
    forkJoin(widgets.map(w => DashboardAPI.updateWidget(w.id, w.settings))).subscribe();
  };
}
