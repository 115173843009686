import React, { memo, lazy } from 'react';
import { Router, PublicRoute, PrivateRoute } from '@core/router';
import { Switch } from 'react-router-dom';

const LoginPage = lazy(() => import('@modules/auth/LoginPage'));
const DashboardPage = lazy(() => import('@modules/dashboard/DashboardPage'));
const ResetPasswordPage = lazy(() => import('@modules/auth/ResetPasswordPage'));
const CreatePasswordPage = lazy(() => import('@modules/auth/CreatePasswordPage'));
const ProposalPreviewPage = lazy(() => import('@modules/proposal/ProposalPreviewPage'));

export const AppRouter = memo(() => (
  <Router>
    <Switch>
      <PublicRoute path="/login" component={LoginPage} />
      <PublicRoute exact path="/create-password" component={CreatePasswordPage} />
      <PublicRoute exact path="/reset-password" component={ResetPasswordPage} />
      <PublicRoute exact path="/proposal-preview/:type/:id" component={ProposalPreviewPage} />

      <PrivateRoute path="/" component={DashboardPage} />
    </Switch>
  </Router>
));
