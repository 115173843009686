import { R } from '@core/utils/r';

interface AppConfig {
  BUILD_MODE: 'dev' | 'production';
  BUILD_ENV: string;

  IS_DEV: boolean;

  GC_API_URL: string;
  GC_URL: string;

  PUSHER_KEY: string;

  DEV_USERNAME?: string;
  DEV_PASSWORD?: string;

  IS_ADMIN: boolean;
}

export const Config: AppConfig = process.env.BUILD_CONFIG as any;

Config.BUILD_MODE = process.env.BUILD_MODE as any;
Config.BUILD_ENV = process.env.BUILD_ENV as any;

Config.IS_DEV = Config.BUILD_MODE === 'dev';
Config.IS_ADMIN = R.toString(Config.IS_ADMIN) === 'true';
